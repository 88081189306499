// since there's no dynamic data here, we can prerender
// it so that it gets served as a static asset in production
// export const prerender = false;
// export const csr = false;

// https://kit.svelte.dev/docs/page-options
// export const prerender = true; // or false or 'auto'
// export const ssr = true; // or false
// export const csr = true; // or false

export const prerender = 'auto';
